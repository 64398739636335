import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material';
import { Router } from '@angular/router';
import { TreeComponent, TreeNode } from 'angular-tree-component';
import * as _ from 'lodash';
import { noop } from 'rxjs';
import { finalize, mergeMap, tap } from 'rxjs/operators';
import { EndpointConfig } from 'src/app/config/endpoint-config';
import { Project, SelectNode, TagDefinition, TaggingColumn } from 'src/app/model/project/project';
import { DialogService } from 'src/app/service/dialog-service';
import { HttpHelper } from '../../utils/http-helper';

export interface ProjectEditTagDefinition extends TagDefinition {
  isExpand: boolean;
  isNew: boolean;
}

export interface ProjectEditTaggingColumn extends TaggingColumn {
  isNew: boolean;
}

export class ProjectEditOperationMixin {

  // マスタ
  public taggingGroups = ['category', 'subclass', 'none']
  public types = ['select', 'colorPicker', 'flag', 'text', 'textArea']
  public taggingLevels = ['item', 'image']

  public pageType: 'Registration' | 'Detail';
  public isEditMode = true

  public project: Project = {
    projectName: '',
    description: '',
    taggingGroup: '',
    taggingPreset: [],
    tagDefinition: [],
    taggingColumn: [],
    isJenkinsTarget: false
  };

  public taggingPresetForm: { label: string, value: string }[] = []
  public tagDefinitions: ProjectEditTagDefinition[] = []
  public taggingColumns: ProjectEditTaggingColumn[] = []

  public addDefinition() {
    this.tagDefinitions.push({
      type: '',
      name: '',
      isNew: true,
      isExpand: false
    })
  }

  public addColumn() {
    this.taggingColumns.push({
      definition: '',
      property: '',
      required: false,
      isVisible: true,
      isArray: false,
      sort: 10,
      taggingLevel: 'item',
      isNew: true
    })
  }

  public changeTypeEvent(index: number, name: string, eventType: MatSelectChange) {
    this.tagDefinitions[index] = this.getDefaultDefinition(eventType.value, name)
    this.tagDefinitions[index].isExpand = true
  }

  public toggleTagDefinition($event: MouseEvent, target: ProjectEditTagDefinition) {
    if ($event.currentTarget == $event.target
      && target.type) {
      target.isExpand = !target.isExpand
    }
  }

  // tree node
  public addSelectNode(index: number, tree: TreeComponent, node: TreeNode) {
    const _initial = {
      name: '',
      children: [],
      isNew: true,
    }

    if (node) {
      node.data.children.push(_initial)
      tree.treeModel.update();
      node.treeModel.focusDrillDown()

    } else {
      // RootNodeのケース
      this.tagDefinitions[index].selectNode.push(_initial)
      tree.treeModel.update();

    }
  }

  disabledRequiredBtn(index: number, target: ProjectEditTaggingColumn) {
    return false
  }

  //isArrayがfalseの場合はcountを削除する。
  public deleteCount(taggingColumn: TaggingColumn) {
    if (taggingColumn['count']) {
      delete taggingColumn['count']
    }
    return false
  }

  public getMinCount(index: number) {
    return 1
  }

  // angular-tree-componentで自動で付与されるid, Clientのみで使用するPropertyを削除する。
  public deletePropertyFromTreeNode(selectNodes: SelectNode[]) {
    for (let selectNode of selectNodes) {
      if (selectNode['id']) {
        delete selectNode['id']
        delete selectNode['isNew']
      }
      if (selectNode.children.length > 0) {
        this.deletePropertyFromTreeNode(selectNode.children)
      }
    }
    return selectNodes
  }

  public deleteTagDefinition(index) {
    this.tagDefinitions.splice(index, 1)
  }

  public deleteTaggingColumn(index) {
    this.taggingColumns.splice(index, 1)
  }

  public deleteSelectNode(index: number, tree: TreeComponent, node: TreeNode) {
    node.parent.data.children.splice(node.index, 1)
    tree.treeModel.update()
  }


  public toTagDefinition(x: ProjectEditTagDefinition) {

    let _x = _.clone(x)
    delete _x.isExpand
    delete _x.isNew

    return _x
  }

  public toTaggingColumn(x: ProjectEditTaggingColumn) {
    let _x = _.clone(x)
    delete _x.isNew

    return _x
  }

  public toDefinitionNameList() {
    return this.tagDefinitions.filter(tagDefinition => tagDefinition.name)
  }

  // testable code.
  public getDefaultDefinition(type: 'select' | 'colorPicker' | 'flag' | 'text' | 'textArea', name: string): ProjectEditTagDefinition {

    let _ret = <ProjectEditTagDefinition>{
      name: name,
      type: type,
      isNew: true,
      isExpand: false
    }

    if (type == 'select') {
      _ret.selectNode = [
        {
          name: '',
          children: [],
          isNew: true,
        }
      ]
    } else if (type == 'text' || type == 'textArea') {
      _ret.maxChar = 30
      _ret.pattern = ''
    }

    return _ret
  }


  isEditable() {
    return this.isEditMode
  }

  isEditableNewOnly() {
    return this.pageType == 'Registration'
  }

  controlTaggingColumnValue(taggingColumn: TaggingColumn){
    taggingColumn.isArray = false
    this.deleteCount(taggingColumn)
  }


  tagDefinitionTypeCheck(taggingColumnDefinition: string, type: string){
    for (let tagDefinition of this.tagDefinitions){
      if (tagDefinition.type == type && tagDefinition.name == taggingColumnDefinition){
        return true
      }
    }

    return false
  }
}


@Component({
  selector: 'app-project-regist',
  templateUrl: './project-regist.component.html',
  styleUrls: ['./project-regist.component.css'],
})
export class ProjectRegistComponent extends ProjectEditOperationMixin implements OnInit {


  static REGIST_CONFIRM = (project: Project) => {
    return `Project: ${project.projectName} <br>
            Tagging Group: ${project.taggingGroup} <br>
            上記のProjectを登録します。よろしいですか？`
  }

  constructor(
    private http: HttpClient,
    private endpointConfig: EndpointConfig,
    private dialog: DialogService,
    private router: Router,
    private httpHelper: HttpHelper,
  ) {
    super()
    this.pageType = 'Registration'
  }

  ngOnInit() {
    if (this.tagDefinitions.length == 0) {
      this.addDefinition()
    }
    if (this.taggingColumns.length == 0) {
      this.addColumn()
    }
  }

  doRegist() {

    this.dialog
      .confirm('Regist', ProjectRegistComponent.REGIST_CONFIRM(this.project))
      .pipe(
        tap(() => this.dialog.loadingShow()),
        mergeMap(x => {
          for (let tagDefinition of this.tagDefinitions) {
            if (tagDefinition.selectNode) {
              tagDefinition.selectNode = this.deletePropertyFromTreeNode(tagDefinition.selectNode)
            }
          }

          // ng-selectで[{ label: 'val' },...]の形式になるため、[ val,... ]の形式に変更する。
          this.project.taggingPreset = this.taggingPresetForm.map(x => x.label)
          this.project.tagDefinition = this.tagDefinitions.map(this.toTagDefinition)
          this.project.taggingColumn = this.taggingColumns.map(this.toTaggingColumn)

          return this.http.post<Project>(this.endpointConfig.riaApi.project.getUrl(), this.project)
        }),
        finalize(() => this.dialog.loadingHide())
      )
      .subscribe(
        () => this.router.navigate(['tag/project-list']),
        (res: HttpErrorResponse) => {
          if (res.status == 400) {
            var messages = []
            res.error.errors.forEach(e => {
              messages.push(e.path + ': ' + e.message)
            })
            this.dialog.error('Error', messages.join('<br>')).subscribe(noop)
          } else {
            this.httpHelper.getDefaultErrorHandler()(res)
          }
        }
      )
  }
}

